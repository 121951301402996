var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',[_c('v-tab',[_vm._v("Operaciones con folios")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Folios de los ultimos 60 días")]),_c('v-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-select',{attrs:{"items":_vm.wellboats,"item-text":"nombre","item-value":"id","label":"Seleccione Wellboat","placeholder":"Seleccione Wellboat","return-object":""},model:{value:(_vm.wellboat),callback:function ($$v) {_vm.wellboat=$$v},expression:"wellboat"}})],1),_c('div',{staticClass:"col"},[_c('v-text-field',{attrs:{"placeholder":"dias","label":"Ver Hasta (Cantidad dias)","dense":""},model:{value:(_vm.dias),callback:function ($$v) {_vm.dias=$$v},expression:"dias"}})],1),_c('div',{staticClass:"col"},[_c('v-text-field',{attrs:{"placeholder":"Folio","label":"N° Folio","dense":""},model:{value:(_vm.folio),callback:function ($$v) {_vm.folio=$$v},expression:"folio"}})],1),_c('div',{staticClass:"col"},[_c('v-btn',{on:{"click":_vm.loadPartesFiltro}},[_vm._v("Filtrar")])],1),_c('div',{staticClass:"col"},[_c('v-btn',{on:{"click":_vm.limpiarFiltros}},[_vm._v("Limpiar")])],1)]),_c('v-data-table',{attrs:{"headers":_vm.partesHeaders,"items":_vm.partes,"search":_vm.search,"header-props":{ sortIcon: 'arrow_upward' },"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'navigate_befores',
              nextIcon: 'navigate_next',
              //'items-per-page-options':[5,10,20],
            }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.sincronizado == 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.sincronizarParte(item)}}},on),[_vm._v(" sync ")])]}}],null,true)},[_c('span',[_vm._v("Sincronizar parte")])]):_vm._e(),(item.sincronizado == 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.desincronizarParte(item)}}},on),[_vm._v(" sync_disabled ")])]}}],null,true)},[_c('span',[_vm._v("Desincronizar")])]):_vm._e(),(item.temporal == 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.habilitarParte(item)}}},on),[_vm._v(" check ")])]}}],null,true)},[_c('span',[_vm._v("Habilitar para edición")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""},on:{"click":function($event){return _vm.deleteParte(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar parte")])])]}}],null,true)})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.validation_message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }